import CloseIcon from '@mui/icons-material/Close'
import { AlertProps, IconButton, Alert as MuiAlert, Slide, SlideProps, Snackbar } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { forwardRef } from 'react'
import { MessageType } from '../../models/Messages'

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="right" />
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

/**
 * Success/Error message snackbar
 * @description displays a success/error message in a snackbar in the top center of the screen
 * @param {string} message - message to display
 * @param {boolean} visible - whether the snackbar is visible
 * @param {function} onClose - function to call when the snackbar is closed
 * @returns {JSX.Element}
 *
 */

export interface IPrimaryMessageProps {
  message: string
  type: MessageType
  visible?: boolean
  onClose?: () => void
}

export default function PrimaryMessage(props: IPrimaryMessageProps) {
  const { message, type, visible = false, onClose } = props
  const { t } = useTranslation('common')

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={visible}
      autoHideDuration={6000}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      sx={{ zIndex: 9999 }}
    >
      <Alert
        severity={type}
        sx={{ width: '100%' }}
        action={
          <IconButton size="small" aria-label={t('close') ?? ''} color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
