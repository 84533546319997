import { ListItem } from '@mui/material'
import { useMemo } from 'react'
import SidenavButton from '../SidenavButton'
import useMe from '../Auth/useMe'

export interface ISidenavItem {
  key: string
  icon: JSX.Element | null
  title: string
  name: string
  onClick?: () => void
  prominent?: boolean
  id?: string
}

export interface ISidenavSection {
  key: string
  title?: string
  items: ISidenavItem[]
  ageGroup?: 'primary' | 'secondary'
}

interface ISectionItemsProps extends Omit<ISidenavSection, 'title' | 'key'> { }

const SidenavListSection = (props: ISectionItemsProps) => {
  const { items } = props
  const { data: profile } = useMe()

  // Function to check whether the item should be excluded
  const shouldExcludeItem = useMemo(() => {
    return (
      profile?.btec &&
      (
        (profile.btec.btecCohort !== 'ZW GRP18' &&
          profile.btec.btecCohort !== 'ZW GRP19' &&
          profile.btec.btecCohort !== 'ZW GRP20') ||
        profile.btec.btecStatus === 'Withdrawn' ||
        profile.btec.btecStatus === null ||
        (profile.btec.btecWithdrawalDate && new Date(profile.btec.btecWithdrawalDate) > new Date())
      )
    )
  }, [profile])

  // Filter and map the items to list components
  const list = useMemo(
    () =>
      items
        .filter((el) => !(shouldExcludeItem && el.name === 'btecCourseManagement'))
        .map((el) => (
          <ListItem key={el.key} disablePadding>
            <SidenavButton
              prominent={el.prominent}
              name={el.name}
              id={el.id}
              icon={el.icon}
              onClick={el.onClick}
              text={el.title}
            />
          </ListItem>
        )),
    [items, shouldExcludeItem]
  )

  return <>{list}</>
}

export default SidenavListSection